import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from '@wix/yoshi-flow-editor';

import { OnboardingStatus } from '@wix/ambassador-groups-onboarding-v1-onboarding/types';

import {
  selectGroup,
  selectOnboarding,
  selectHasAdminRole,
} from 'store/selectors';
import { useController } from 'common/context/controller';

import { Box } from 'wui/Box';
import { Spinner } from 'wui/Spinner';
import { IconButton } from 'wui/IconButton';
import { EmptyState } from 'wui/EmptyState';
import { CardHeader } from 'wui/CardHeader';
import { TextButton } from 'wui/TextButton';
import { Card, type ICardProps } from 'wui/Card';
import {
  Close as CloseIcon,
  Error as ErrorIcon,
  Replace as ReplaceIcon,
  BadgeFill as BadgeFillIcon,
} from '@wix/wix-ui-icons-common/on-stage';

import { OnboardingSteps } from './OnboardingSteps';

import classes from './Onboarding.scss';

type OnboardingProps = {
  groupId: string;
};

export function Onboarding(props: OnboardingProps & ICardProps) {
  const { groupId, ...rest } = props;

  const { t } = useTranslation();
  const { onboarding$ } = useController();

  const group = useSelector(selectGroup(groupId));
  const isAdmin = useSelector(selectHasAdminRole(groupId));

  const {
    data: { status, steps },
    meta: { loading: isLoading, error: isError },
  } = useSelector(selectOnboarding);

  const isHidden = [
    OnboardingStatus.INITIALIZING,
    OnboardingStatus.COMPLETED,
  ].includes(status);

  const isCompleted = steps.every((step) => step.status === 'COMPLETED');

  if (isHidden) {
    return null;
  }

  if (isLoading) {
    return (
      <Card gap={0} {...rest} data-status="loading">
        <EmptyState
          variant="section"
          title={
            <Box align="center" direction="vertical">
              <Spinner size={34} />
            </Box>
          }
        />
      </Card>
    );
  }

  if (isError) {
    return (
      <Card gap={0} {...rest} data-status="error">
        <EmptyState
          variant="section"
          image={<ErrorIcon size="32" />}
          title={t('groups-web.onboarding.error.title')}
          subtitle={t('groups-web.onboarding.error.subtitle')}
          action={
            <TextButton
              prefixIcon={<ReplaceIcon />}
              onClick={() => onboarding$.initialize(props.groupId)}
            >
              {t('groups-web.onboarding.error.refresh')}
            </TextButton>
          }
        />
      </Card>
    );
  }

  if (isCompleted) {
    return (
      <Card gap={0} {...rest} data-status="completed">
        <CardHeader
          wired
          data-hook="onboarding-card-header"
          action={
            <IconButton
              icon={<CloseIcon />}
              onClick={() => onboarding$.closeOnboarding(groupId)}
              aria-label={t('groups-web.a11y.close')}
            />
          }
        />

        <EmptyState
          variant="section"
          data-hook="onboarding-empty"
          image={<BadgeFillIcon className={classes.completedIcon} size="32" />}
          title={
            isAdmin
              ? t('groups-web.onboarding.success.admin.title')
              : t('groups-web.onboarding.success.member.title')
          }
          subtitle={
            isAdmin
              ? t('groups-web.onboarding.success.admin.subtitle', {
                  groupName: group?.name,
                })
              : t('groups-web.onboarding.success.member.subtitle', {
                  groupName: group?.name,
                })
          }
        />
      </Card>
    );
  }

  return (
    <Card {...rest}>
      <CardHeader
        wired
        data-hook="onboarding-card-header"
        title={
          isAdmin
            ? t('groups-web.onboarding.title.admin')
            : t('groups-web.onboarding.title.member')
        }
        action={
          <IconButton
            onClick={() => onboarding$.closeOnboarding(groupId)}
            icon={<CloseIcon />}
            aria-label={t('groups-web.a11y.close')}
          />
        }
      />

      <OnboardingSteps groupId={groupId} data-hook="onboarding-steps" />
    </Card>
  );
}

Onboarding.displayName = 'Onboarding';
